<template>
	<v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
		<template v-slot:activator="{ on, attrs }">
			<v-btn fab fixed bottom right color="accent" v-bind="attrs" v-on="on">
				<v-icon>mdi-share</v-icon>
			</v-btn>
		</template>
		<v-card class="pt-0" style="min-height: 100vh" color="background">
			<v-container style="min-height: 100vh">
				<v-row class="flex-column" style="min-height: 100vh">
					<v-col>
						<v-toolbar dark class="accent" color="red">
							<v-toolbar-title>share</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-toolbar-items>
								<v-btn color="accent" elevation="0" small @click="showHelp = !showHelp">
									<v-icon small>mdi-help</v-icon>
								</v-btn>
								<v-btn dark text @click="saveShares">save</v-btn>
							</v-toolbar-items>
						</v-toolbar>
						<v-row>
							<v-col cols="12">
								<v-alert color="accent" :value="showHelp">
									<p>Select a connection to share this memory with them.</p>
									<p>If you are not already connected, just add their email address and we'll invite them for you.</p>
									<v-row>
										<v-spacer></v-spacer>
										<v-col cols="auto">
											<v-btn text @click="showHelp = false">got it</v-btn>
										</v-col>
									</v-row>
								</v-alert>
							</v-col>

							<v-col cols="12">
								<v-combobox
									class="px-2"
									ref="combo"
									label="select connections"
									:items="selectList"
									:item-text="(item) => `${item.name} < ${item.email} >`"
									item-value="id"
									@change="addUser"
									hint="select a connection, or enter an email address."
									persistent-hint
								></v-combobox>
							</v-col>

							<v-col cols="12">
								<v-list class="background">

									<v-list-item v-for="(user, i) in new_connections">
										<v-list-item-avatar>
											<v-icon color="accent">mdi-share</v-icon>
										</v-list-item-avatar>

										<v-list-item-content>
											<v-list-item-title>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-icon v-bind="attrs" v-on="on" v-if="user.status === 'pending'" color="deep-purple lighten-3" class="me-3">mdi-account-clock</v-icon>
														<v-icon v-bind="attrs" v-on="on" v-if="user.status === 'accepted'" color="white">mdi-account-check</v-icon>
													</template>
													<span>{{ user.status }}</span>
												</v-tooltip>
												{{ user.email }}
											</v-list-item-title>
										</v-list-item-content>

										<v-list-item-action>
											<v-btn icon @click="removeFromNewList(i)">
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</v-list-item-action>
									</v-list-item>

									<v-list-item v-for="(user, i) in existing_connections">
										<v-list-item-avatar>
											<v-icon color="accent">mdi-share</v-icon>
										</v-list-item-avatar>

										<v-list-item-content>
											<v-list-item-title>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-icon v-bind="attrs" v-on="on" v-if="user.status === 'pending'" color="deep-purple lighten-3" class="me-3">mdi-account-clock</v-icon>
														<v-icon v-bind="attrs" v-on="on" v-if="user.status === 'accepted'" color="white">mdi-account-check</v-icon>
													</template>
													<span>{{ user.status }}</span>
												</v-tooltip>
												{{ lookupFriend(user) }}
											</v-list-item-title>
										</v-list-item-content>

										<v-list-item-action>
											<v-btn icon @click="removeFromExistingList(i)">
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</v-list-item-action>
									</v-list-item>
								</v-list>
							</v-col>
						</v-row>

					</v-col>
					<v-col cols="auto">
						<v-row>
							<v-col cols="12" @click="showGetPublicLink = !showGetPublicLink" class="hover_pointer">
								<v-icon>mdi-link</v-icon>
								public link
							</v-col>
							<v-col cols="12" v-if="showGetPublicLink">
								<v-alert color="accent">
									<v-row>
										<v-col cols="12">
											create a public link to this memory. <br> anyone with the link can view it.
										</v-col>
										<v-col cols="12" v-if="!share_token">
											<v-row class="align-end">
												<v-col>
													<v-select label="expire after" hide-details :items="expiry_days" v-model="expire_after"></v-select>
												</v-col>
												<v-col>
													<v-btn @click="createPublicLink">get link</v-btn>
												</v-col>
											</v-row>
										</v-col>
										<v-col cols="12" v-else>
											<v-text-field
												v-model="public_share_url"
												:disabled="!share_token"
												:append-icon="share_token ? 'mdi-content-copy' : ''"
												:label="share_token ? 'public link' : 'no link created'"
												readonly
												solo
												hide-details
												ref="public_share_url"
												@click:append="copyTextToClipboard"
											></v-text-field>
										</v-col>
										<v-col cols="12" v-if="share_token">
											<v-row class="pt-0">
												<v-col cols="auto" class="py-0">
													<v-btn @click="shareOnSocialMedia('facebook')" icon style="min-height: unset">
														<v-icon>mdi-facebook</v-icon>
													</v-btn>
												</v-col>
												<!--												<v-col cols="auto" class="py-0">-->
												<!--													<v-btn @click="shareOnSocialMedia('instagram')" icon style="min-height: unset">-->
												<!--														<v-icon>mdi-instagram</v-icon>-->
												<!--													</v-btn>-->
												<!--												</v-col>-->
												<!--												<v-col cols="auto" class="py-0">-->
												<!--													<v-btn @click="shareOnSocialMedia('linkedin')" icon style="min-height: unset">-->
												<!--														<v-icon>mdi-linkedin</v-icon>-->
												<!--													</v-btn>-->
												<!--												</v-col>-->
												<v-col cols="auto" class="py-0">
													<v-btn @click="shareOnSocialMedia('twitter')" icon style="min-height: unset">
														<v-icon>mdi-twitter</v-icon>
													</v-btn>
												</v-col>
												<v-col cols="auto" class="py-0">
													<v-btn @click="shareOnSocialMedia('email')" icon style="min-height: unset">
														<v-icon>mdi-email</v-icon>
													</v-btn>
												</v-col>
											</v-row>
										</v-col>
										<v-col cols="12" v-if="share_token">
											<v-btn @click="deletePublicLink">delete link</v-btn>
										</v-col>
									</v-row>
								</v-alert>
							</v-col>
						</v-row>
					</v-col>
				</v-row>

			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>

import {sync} from "vuex-pathify";
import {Clipboard} from '@capacitor/clipboard';
import {DateTime} from "luxon";

export default {
	name: "ShareDialog",
	props: ['existing_shared_list', 'memory'],
	computed: {
		showHelp: sync('app/showShareHelp'),
		user: sync('user/user'),
		selectList() {
			return this.friends.filter((o) => this.existing_shared_list.indexOf(o.id) === -1)
		},
		public_share_url: {
			get() {
				return `https://app2.rewind-remind.me/public/memory/${this.share_token}`
			},
			set() {
				// do nothing
			}
		},
		share_token_expires_in_days() {
			let days = 0
			if (this.memory.share_token_expiry_date) {
				days = DateTime.fromISO(this.memory.share_token_expiry_date).diffNow('days').days
			} else {
				let expiry_date = DateTime.local().plus({days: this.expire_after})
				days = expiry_date.diffNow('days').days
			}
			return Math.round(days)
		}
	},
	data: () => ({
		dialog: false,
		showGetPublicLink: false,
		friends: [],
		existing_connections: [],
		new_connections: [],
		share_token: null,
		expiry_days: [
			{text: '1 day', value: 1},
			{text: '1 week', value: 7},
			{text: '1 month', value: 30},
			{text: '3 months', value: 90},
			{text: '6 months', value: 180},
			{text: '1 year', value: 365},
			{text: 'never', value: 'never'},
		],
		expire_after: 7,
	}),
	mounted() {
		this.existing_connections = this.existing_shared_list
		this.getFriendsList()
		this.share_token = this.memory.share_token
	},
	watch: {
		existing_shared_list() {
			this.existing_connections = this.existing_shared_list
		},
		'memory.share_token'() {
			this.share_token = this.memory.share_token
		}
	},
	methods: {
		removeFromNewList(i) {
			this.new_connections.splice(i, 1)
		},
		removeFromExistingList(i) {
			this.existing_connections.splice(i, 1)
		},
		getFriendsList() {
			this.$api.friends.index()
				.then(response => this.friends = response.data)
				.catch(error => console.log(error))
		},
		addUser(user) {
			this.$refs.combo.internalValue = ''
			this.$refs.combo.internalSearch = ''
			if (user && typeof user === 'object') {
				this.existing_connections.push({
					id: user.id,
					name: user.name,
					email: user.email,
					avatar: user.avatar,
				})
			} else if (user) {
				if (this.validateEmail(user)) {
					this.new_connections.push({
						id: null,
						name: null,
						email: user,
						avatar: null,
					})
				}
			}
		},
		lookupFriend(user) {
			if (user.id) {
				let x = this.friends.find(obj => obj.id === user.id)
				return x ? x.name : 'unknown user'
			} else if (user.email) {
				return user.email
			} else {
				return 'unknown user'
			}
		},
		validateEmail(email) {
			let re = /\S+@\S+\.\S+/;
			return re.test(email);
		},
		saveShares() {
			let share_list = {
				existing: this.existing_connections,
				new: this.new_connections,
			}
			this.$api.memories.share(this.memory.id, share_list)
				.then(response => {
					this.dialog = false
				})
				.catch(error => console.log(error))
		},
		createPublicLink() {
			this.$api.memories.create_public_link(this.memory.id, this.expire_after)
				.then(response => {
					this.$set(this.memory, 'share_token', response.data.share_token)
					this.share_token = response.data.share_token
				})
				.catch(error => console.log(error))
		},
		deletePublicLink() {
			this.$api.memories.delete_public_link(this.memory.id)
				.then(response => {
					console.log('response', response)
					this.share_token = null
					this.$set(this.memory, 'share_token', response.data.share_token)
				})
				.catch(error => console.log(error))
		},
		async copyTextToClipboard() {
			if (this.$platform == null) return;
			let inputElement = this.$refs.public_share_url
			let textToCopy = `${this.user.first_name} has shared a memory with you\ncheck out: ${this.memory.title} on rewind\nfor security, this link will expire after ${this.share_token_expires_in_days} days\n${inputElement.value}`

			// Use the Capacitor Clipboard plugin to copy the text to the clipboard
			Clipboard.write({
				string: textToCopy
			})
				.then(() => {
					this.$toast.add('Copied to clipboard', 'success')
					console.log('Text copied to clipboard!');
				})
				.catch((err) => {
					this.$toast.add('Oops, unable to copy', 'error')
					console.error('Failed to copy text:', err);
				});
		},
		shareOnSocialMedia(platform) {
			let url = this.public_share_url
			let title = this.memory.title
			let image = this.memory.featured_image.thumb_url
			let caption = this.memory.description
			this.share(url, title, image, caption, platform)
		},
		share(url, title, image, caption, platform) {
			if (platform == null) return
			// Encode the article URL, title, image, and caption
			let encodedUrl = encodeURIComponent(url);
			let encodedTitle = encodeURIComponent(title);
			let encodedImage = encodeURIComponent(image);
			let encodedCaption = encodeURIComponent(caption);

			// Set the dimensions and other options for the share dialog
			let windowFeatures = 'width=800,height=800,menubar=no,toolbar=no,resizable=yes,scrollbars=yes';


			// Open the share dialog based on the selected platform
			switch (platform.toLowerCase()) {
				case 'facebook':
					window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodedUrl, '_blank', windowFeatures);
					break;
				case 'twitter':
					console.log('https://twitter.com/share?url=' + encodedUrl + '&text=' + encodedTitle);
					window.open('https://twitter.com/share?url=' + encodedUrl + '&text=' + encodedTitle, '_blank', windowFeatures);
					break;
				case 'linkedin':
					window.open('https://www.linkedin.com/shareArticle?url=' + encodedUrl + '&title=' + encodedTitle + '&summary=' + encodedCaption + '&source=', '_blank', windowFeatures);
					break;
				// case 'instagram':
				// 	window.open('https://www.instagram.com/sharing/url=' + encodedUrl + '&title=' + encodedTitle + '&caption=' + encodedCaption + '&media=' + encodedImage, '_blank', windowFeatures);
				// 	break;
				case 'email':
					let subject = `${this.memory.title}`;
					let body = `${this.user.first_name} has shared a memory with you\ncheck out: ${this.memory.title} on rewind\nfor security, this link will expire after ${this.share_token_expires_in_days} days\n${inputElement.value}`


					let encodedSubject = encodeURIComponent(subject);
					let encodedBody = encodeURIComponent(body);
					let mailtoLink = 'mailto:?subject=' + encodedSubject + '&body=' + encodedBody;

					window.open(mailtoLink, '_blank');
					break;
				default:
					console.error('Invalid platform specified');
			}
		},
	}
}
</script>
